import {useFetch} from "../service/api/useFetch";
import {Grid} from "@mui/material";
import {Loading} from "../compontents/Loading";
import {Tournament} from "../model/Tournament";
import {TournamentCard} from "../compontents/TournamentCard/TournamentCard";
import {useNavigate} from "react-router-dom";
import currentUserService from "../service/currentUserService";
import React from "react";
import dayjs from "dayjs";

export const TournamentOverview = () => {

    const {data, loading, error} = useFetch<Tournament[]>('tournaments');
    const navigate = useNavigate();

    if (error || !currentUserService.getCurrentUserName()) {
        navigate("/login")
    }

    return (
        <>
            {loading && <Loading/>}
            {data &&
                <Grid container spacing={3}>
                    {data.sort((a, b) => {
                        if (!a.start && b.start) return 1
                        if (!b.start && a.start) return -1
                        const aStartDate = dayjs(a.start) ;
                        const bStartDate = dayjs(b.start);

                       return  aStartDate.isBefore(bStartDate) ? -1 : 1
                    }).map(tournament => (
                        <Grid key={tournament.id} item xs={12} md={6} lg={4} xl={3}>
                            <TournamentCard key={tournament.id} tournament={tournament}/>
                        </Grid>
                    ))}
                </Grid>}

        </>
    );

};


