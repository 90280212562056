import {RegisterPlayerRequest} from "../../model/dtos/RegisterPlayerRequest";
import axios from "axios";
import {appConfig} from "../../Constants";
import authHeader from "./authHeader";

export const postPlayer = async (player: RegisterPlayerRequest ) => {
    try {
        const {data: response} = await axios.post(appConfig.SERVER_URL + '/auth/register', player, {headers: authHeader()!});
        return response;
    } catch (error) {
        console.error(error)
    }
}
