import {appConfig} from "../../Constants";

export default function authHeader() {

  const jwtToken = localStorage.getItem(appConfig.LOCAL_STORAGE_KEYS.JWT_TOKEN);

  if (jwtToken ) {
    // return { Authorization: 'Bearer ' + user.accessToken };
    return { Authorization: jwtToken};
  } else {
    return null;
  }
}
