import {appConfig} from "../../Constants";
import {Player} from "../../model/Player";
import axios from "axios";
import authHeader from "./authHeader";

const playerService = () => {

    const urlPrefix = `${appConfig.SERVER_URL}/players`

    const updatePlayer = async (player: Player) => {
      return axios.put<Player>(`${urlPrefix}/${player.id}`, player, {headers: authHeader()!})
    }

    const deletePlayer = async (id: number) => {
        return axios.delete(`${urlPrefix}/${id}`, {headers: authHeader()!})
    };

    return{
        updatePlayer,
        deletePlayer,
    }
}

export default playerService();
