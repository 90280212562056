import {AppBar, Box, IconButton, Toolbar, Tooltip, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React, {useState} from "react";
import {appConfig} from "../../Constants";
import currentUserService from "../../service/currentUserService";
import {AccountButton} from "./AccountButton";
import {NavDrawer} from "./NavDrawer";


export const CustomAppBar = () => {


    return (
        <>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar>
                        <NavDrawer></NavDrawer>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            {appConfig.SITE_NAME}
                        </Typography>
                        <AccountButton/>
                    </Toolbar>
                </AppBar>
            </Box>
        </>

    );
}
