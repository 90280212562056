import {appConfig} from "../../Constants";
import axios from "axios";
import {Tournament, TournamentInput} from "../../model/Tournament";
import authHeader from "./authHeader";
import {useNavigate} from "react-router-dom";

const urlPrefix = `${appConfig.SERVER_URL}/tournaments`

export const useTournamentService = () => {
  const navigate = useNavigate();
  if (!authHeader()) navigate("/login")

    const unRegisterCurrentUserFromTournament = async (tournamentId: number) => {
        return axios.put<Tournament>(`${urlPrefix}/${tournamentId}/self-unregister`, null, {headers: authHeader()!})
    }

    const registerCurrentUserForTournament = async (tournamentId: number) => {
        return axios.put<Tournament>(`${urlPrefix}/${tournamentId}/self-register`, null, {headers: authHeader()!})
    }

    const unRegisterUserFromTournament = async (tournamentId: number, playerId: number) => {
        return axios.put<Tournament>(`${urlPrefix}/${tournamentId}/unregister/${playerId}`, null, {headers: authHeader()!})
    }

    const registerUserForTournament = async (tournamentId: number, playerId: number) => {
        return axios.put<Tournament>(`${urlPrefix}/${tournamentId}/register/${playerId}`, null, {headers: authHeader()!})
    };

    const postTournament = async (tournament: TournamentInput): Promise<Tournament | undefined> => {
        try {
            const {data: response} = await axios.post(appConfig.SERVER_URL + '/tournaments', tournament, {headers: authHeader()!});
            return response;
        } catch (error) {
            console.error(error)
        }
    }

    const deleteTournament = async (tournamentId: number) => {
     await axios.delete(`${urlPrefix}/${tournamentId}`, {headers: authHeader()!})
    }

    return{
        unRegisterUserFromTournament,
        registerUserForTournament,
        postTournament,
        registerCurrentUserForTournament,
        unRegisterCurrentUserFromTournament,
        deleteTournament
    }
}

