import {Box, Button, Checkbox, FormControlLabel, Grid, Stack, TextField, Typography} from "@mui/material";
import useAxios from "axios-hooks";
import {Player} from "../../../model/Player";
import {appConfig} from "../../../Constants";
import authHeader from "../../../service/api/authHeader";
import {useParams} from "react-router-dom";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {roleService} from "../../../service/roleService";
import {ChangeEvent, useEffect, useState} from "react";
import playerService from "../../../service/api/playerService";
import {useRequireAdminRole} from "../../../hooks/useRequireAdminRole";
import axios from "axios";

export const EditPlayerPage = () => {

    useRequireAdminRole();

    const params = useParams();
    const [player, setPlayer] = useState<Player>({
        id: 0,
        name: "",
        login: "",
        roles: []
    });
    const [refreshedPassword, setRefreshPassword] = useState("")


    const [{data, loading, error}, refetch] = useAxios<Player>({
        url: `${appConfig.SERVER_URL}/players/${params.id}`,
        method: "GET",
        headers: authHeader()!,
    });

    useEffect(() => {
        if (data) setPlayer(data)
    }, [data]);

    const playerIsAdmin = () => {
        return data && roleService().playerIsAdmin(data);
    }

    function handleNameChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setPlayer(prevState => ({...prevState, name: event.target.value}))
    };

    function handleLoginChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setPlayer(prevState => ({...prevState, login: event.target.value}))
    }

    const handleSubmit = async () => {
        await playerService.updatePlayer(player);
        refetch();
    };

    function isValidInput() {
        return true;
    }

    function handleAdminChange(event: ChangeEvent<HTMLInputElement>) {
        const isChecked = event.target.checked;
        if (isChecked) {
            setPlayer(prevState => ({
                ...prevState,
                roles: player.roles.concat({authority: appConfig.AUTHORITIES.ADMIN})
            }));
        } else {
            setPlayer(prevState => ({
                ...prevState,
                roles: player.roles.filter(value => value.authority !== appConfig.AUTHORITIES.ADMIN)
            }));
        }

    }

    const handlePasswordReset = async () => {
        const axiosResponse = await axios.put<string>(`${appConfig.SERVER_URL}/auth/${player.id}/refresh-password`, null, {headers: authHeader()!});
        if (axiosResponse) setRefreshPassword(axiosResponse.data)
    };

    return (
        <>
            {data &&
                <Grid container>
                    <Grid item xs={6}>
                        <Stack direction={"column"} spacing={3}>
                            <Typography variant={"h5"}>{data.name}</Typography>
                            <Stack direction={'column'} spacing={3}>
                                <TextField label={'name'} defaultValue={data.name} onChange={handleNameChange}/>
                                <TextField label={'login'} defaultValue={data.login} onChange={handleLoginChange}/>
                                <FormControlLabel
                                    control={<Checkbox defaultChecked={playerIsAdmin()} onChange={handleAdminChange}/>}
                                    label="Admin"/>
                            </Stack>
                            <Box width={'50%'}>
                                <Button variant={"contained"} disabled={!isValidInput()}
                                        onClick={handleSubmit}>Speichern</Button>
                            </Box>
                            <Box width={'50%'}>
                                <Button variant={"contained"} disabled={!isValidInput()}
                                        onClick={handlePasswordReset}>Password zurücksetzen</Button>
                                {refreshedPassword && <TextField label={'Neues Password'} value={refreshedPassword}
                                                                 disabled={true}/>}
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            }
        </>
    )
}
