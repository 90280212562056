import React, {ChangeEvent, useEffect, useState} from "react";
import {Tournament, TournamentInput} from "../../model/Tournament";
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import useAxios from "axios-hooks";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import {useNavigate, useParams} from "react-router-dom";
import {Loading} from "../../compontents/Loading";
import {Error} from "../../compontents/Error";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {SelectUserModal} from "../../compontents/SelectUserModal";
import {appConfig} from "../../Constants";
import authHeader from "../../service/api/authHeader";
import {useFetch} from "../../service/api/useFetch";
import {TournamentEntries} from "../../model/TournamentEntries";
import {useSnackbar} from "notistack";
import {useTournamentService} from "../../service/api/tournamentService";
import {useRequireAdminRole} from "../../hooks/useRequireAdminRole";
import {AlertDialog} from "../../compontents/AlertDialog";


export const EditTournament = () => {

    const snackbar = useSnackbar();
    const tournamentService = useTournamentService()

    useRequireAdminRole();

    const params = useParams();
    const navigate = useNavigate();

    const [openPlayerModal, setOpenPlayerModal] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const tournamentId = +params.id!;

    const [hasDate, setHasDate] = useState(false);

    const [{
        data: tournamentData,
        error: tournamentError,
        loading: tournamentLoading
    }, refetchTournament] = useAxios<Tournament>({
        url: `${appConfig.SERVER_URL}/tournaments/${tournamentId}`,
        method: "GET",
        headers: authHeader()!,
    });

    const {
        data: registrationsData,
        error: registrationsError,
        loading: registrationsLoading,
        refetch: registrationRefetch
    } = useFetch<TournamentEntries>(`tournaments/${tournamentId}/entries`);


    useEffect(() => {
        if (tournamentData) setInput(tournamentData);
    }, [tournamentData]);

    const [input, setInput] = useState<Tournament>({
        id: 0,
        name: "",
        openForRegistration: false,
        maxPlayers: 20,
        start: new Date(),
        registrationIds: [],
    })

    const handleSubmit = async () => {
        try {
            await tournamentService.postTournament(input);
            await registrationRefetch();
            snackbar.enqueueSnackbar('Die Änderungen wurden gespeichert', {variant: "success"});
        } catch (e) {
            snackbar.enqueueSnackbar("Uuups etwas ist schief gelaufen", {variant: "error"});
            console.error(e)
        }
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, name: event.target.value}))
    };

    const handleStartTimeChange = (value: Date | null) => {
        if (value) {
            setInput(prevState => ({...prevState, start: value}))
        }
    }

    const handleMaxPlayerChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, maxPlayers: +event.target.value}))
    };


    const handlePlayerDialogClose = async (playerId?: number) => {
        setOpenPlayerModal(false);
        if (!playerId) return;
        try {
            await tournamentService.registerUserForTournament(tournamentId, playerId)
            snackbar.enqueueSnackbar(`Spieler regestriert`, {variant: "success"})
        } catch (e) {
            snackbar.enqueueSnackbar("Uuups etwas ist schief gelaufen", {variant: "error"});
            console.error(e)
        }
    }

    const handleOpenForRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(prevState => ({...prevState, openForRegistration: event.target.checked}))
    };

    const handlePlayerDelete = async (playerId: number) => {
        await tournamentService.unRegisterUserFromTournament(tournamentId, playerId);
        registrationRefetch();
    }

    const handleDeleteDialogAccept = async () => {
        setOpenDeleteDialog(false);
        await tournamentService.deleteTournament(tournamentId);
        navigate("/");
    }

    const handleDeleteDialogCancel = () => {
        setOpenDeleteDialog(false);
    }


    const handleDateCheckBox = () => {
        if (input.start) setInput(prevState => ({...prevState, start: undefined}))
        else setInput(prevState => ({...prevState, start: new Date()}))

    };

    const handlePasteClick = async () => {

        if (registrationsData) {
            const names = registrationsData.registered.map(value => value.player.name).join("\n");
            await navigator.clipboard.writeText(names);
            snackbar.enqueueSnackbar("Teilnehmer wurden in die Zwischenablage kopiert", {variant: "success"})
        }
    };

    if (tournamentLoading) return <Loading/>

    if (tournamentData) {
        return (
            <>
                <AlertDialog
                    title={"Achtung"}
                    open={openDeleteDialog}
                    onAccept={handleDeleteDialogAccept}
                    onCancel={handleDeleteDialogCancel}
                    content={"Willst du das Turnier wirklich löschen?"}
                />
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <form onSubmit={handleSubmit}>
                            <Stack direction={'column'} spacing={2}>
                                <TextField name={"Name"} label={"Name"} value={input.name} type={"text"}
                                           onChange={handleNameChange}/>
                                <Stack direction={"row"}>
                                    <DateTimePicker
                                        disabled={!input.start}
                                        onChange={handleStartTimeChange}
                                        value={input.start}
                                        label={"Start"}
                                        renderInput={(props) => <TextField {...props}/>}
                                    />
                                    <Checkbox checked={!!input.start} onChange={handleDateCheckBox}></Checkbox>
                                </Stack>

                                <TextField name={"MaxPlayers"} label={"Max Spieler"} value={input.maxPlayers}
                                           type={"number"}
                                           onChange={handleMaxPlayerChange}/>
                                <FormControlLabel
                                    label={"Offen für Anmeldungen"}
                                    control={<Checkbox checked={input.openForRegistration}
                                                       defaultChecked={input.openForRegistration}
                                                       onChange={handleOpenForRegistrationChange}/>}
                                />
                                <Stack direction={"row"} spacing={2}>
                                    <Button variant={"contained"} onClick={handleSubmit}>Speichern</Button>
                                    <Button variant={"contained"}
                                            onClick={() => setOpenDeleteDialog(true)}>Löschen</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        {registrationsData &&
                            <>
                                <List dense sx={{width: '50%'}}>
                                    <ListSubheader>Anmeldungen</ListSubheader>
                                    {registrationsData.registered.map(re => (
                                        <ListItem key={re.id}>
                                            <ListItemText>{re.player.name}</ListItemText>
                                            <Tooltip title={"Spieler abmelden"}>
                                                <IconButton onClick={() => handlePlayerDelete(re.player.id)}
                                                            sx={{width: "fit-content"}}>
                                                    <PersonRemoveIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItem>
                                    ))}
                                </List>
                                <Button
                                    variant={"contained"}
                                    startIcon={<ContentPasteIcon/>}
                                    onClick={handlePasteClick}
                                    sx={{
                                        marginBottom: 2
                                    }}
                                >
                                    Teilnehmer kopieren
                                </Button>
                                <Divider/>
                                <List dense sx={{width: '50%'}}>
                                    <ListSubheader>Warteliste</ListSubheader>
                                    {registrationsData.waitingList.map(value => (
                                        <ListItem key={value.id}>
                                            <ListItemText>{value.player.name}</ListItemText>
                                            <IconButton onClick={() => handlePlayerDelete(value.player.id)}
                                                        sx={{width: "fit-content"}}>
                                                <PersonRemoveIcon/>
                                            </IconButton>
                                        </ListItem>
                                    ))}
                                </List>

                            </>
                        }

                        <Tooltip title={"Spieler anmelden"}>
                            <IconButton onClick={() => setOpenPlayerModal(true)}>
                                <PersonAddIcon/>
                            </IconButton>
                        </Tooltip>

                    </Grid>
                    <SelectUserModal isOpen={openPlayerModal} onClose={handlePlayerDialogClose}></SelectUserModal>
                </Grid>
            </>

        );
    }
    return <Error/>
}
