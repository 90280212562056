import {Box, Link, Typography} from "@mui/material";

export const ForbiddenSite = () => {
  return(
      <>
        <Typography variant={"h2"}>403 </Typography>
        <Typography variant={"h2"}>Zugriff verweigert </Typography>
        <Link href={"/login"}>Login</Link>
      </>
  )
}
