import {Button, Checkbox, FormControlLabel, Grid, Stack, TextField} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";
import React, {ChangeEvent, useState} from "react";
import {useTournamentService} from "../../service/api/tournamentService";
import {TournamentInput} from "../../model/Tournament";
import {useNavigate} from "react-router-dom";
import {useRequireAdminRole} from "../../hooks/useRequireAdminRole";

export const AddTournamentPage = () => {

    useRequireAdminRole();

    const tournamentService = useTournamentService();
    const navigate = useNavigate();

    const [input, setInput] = useState<TournamentInput>({
        name: "",
        openForRegistration: false,
        maxPlayers: 20,
        start:new Date(),
        registrationIds: [],
    })

    const [hasDate, setHasDate] = useState(false);

    const handleSubmit = async () => {
        const tournament: TournamentInput = {...input, start: hasDate ? input.start : null}
        await tournamentService.postTournament(tournament);
        navigate("/")
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, name: event.target.value}))
    };

    const handleStartTimeChange = (value: Date | null) => {
        if (value) {
            setInput(prevState => ({...prevState, start: value}))
        }
    }

    const handleMaxPlayerChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, maxPlayers: +event.target.value}))
    };

    const handleOpenForRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(prevState => ({...prevState, openForRegistration: event.target.checked}))
    };

    const handleDateCheckBox = () => {
        setHasDate(!hasDate);
    };

    return(
    <Grid container>
        <Grid item xs={12} md={6}>
            <form onSubmit={handleSubmit}>
                <Stack direction={'column'} spacing={2}>
                    <TextField name={"Name"} label={"Name"} type={"text"} onChange={handleNameChange}/>
                    <Stack direction={"row"}>
                        <DateTimePicker
                            onChange={handleStartTimeChange}
                            value={input.start}
                            label={"Start"}
                            renderInput={(props) => <TextField {...props}/>}
                            disabled={!hasDate}
                        />
                        <Checkbox onChange={handleDateCheckBox}></Checkbox>

                    </Stack>
                    <TextField name={"MaxPlayers"} label={"Max Spieler"} type={"number"} onChange={handleMaxPlayerChange}/>
                    <FormControlLabel
                        label={"Offen für Anmeldungen"}
                        control={<Checkbox onChange={handleOpenForRegistrationChange}/>}
                    />
                    <Stack direction={"row"} spacing={2}>
                        <Button variant={"contained"} onClick={handleSubmit}>Speichern</Button>
                    </Stack>
                </Stack>
            </form>
        </Grid>


    </Grid>

  )
}
