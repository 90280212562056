import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import {useFetch} from "../../../service/api/useFetch";
import {NotificationConsumer} from "../../../model/NotificationConsumer";
import {Loading} from "../../../compontents/Loading";
import {Error} from "../../../compontents/Error";
import {ChangeEvent, useState} from "react";
import notificationConsumerService from "../../../service/api/NotificationConsumerService";
import {Delete} from "@mui/icons-material";
import {useSnackbar} from "notistack";
import {useRequireAdminRole} from "../../../hooks/useRequireAdminRole";

export const EmailDistributor = () => {

    useRequireAdminRole();

    const snackbar = useSnackbar();

    const initialState = {
        name: "",
        email: ""
    };
    const [input, setInput] = useState(initialState)

    const {data, error, loading, refetch,} = useFetch<NotificationConsumer[]>("notification-consumers");

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, name: event.target.value}))
    };

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, email: event.target.value}))
    };

    const handleSubmit = async () => {
        try {
            await notificationConsumerService.post(input)
            setInput(initialState)
            snackbar.enqueueSnackbar(`${input.name} wird nun über wichtige Ereignisse informiert`, {variant: "success"})
            refetch();
        } catch (e) {
            snackbar.enqueueSnackbar('Uuups etwas ist schief gelaufen', {variant: "error"})
            console.error(e)
        }

    }

    const handleDeleteClick = async (id: number) => {
        try {
            await notificationConsumerService.deleteById(id)
            refetch();
            snackbar.enqueueSnackbar(`Der Spieler bekommt keine Emails mehr`, {variant: "success"})
        }catch (e) {
            snackbar.enqueueSnackbar('Uuups etwas ist schief gelaufen', {variant: "error"})
            console.error(e)
        }

    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Stack direction={"column"} spacing={3}>
                        <Stack direction={"row"} spacing={3}>
                            <TextField label={"Name"} type={"text"} onChange={handleNameChange}></TextField>
                            <TextField label={"E-Mail"} type={"email"} onChange={handleEmailChange}></TextField>
                        </Stack>
                        <Box>
                            <Button variant={"contained"} onClick={handleSubmit}>Speichern</Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        {loading && <Loading/>}
                        {error && <Error/>}
                        {data &&
                            <TableContainer component={Paper}>
                                <Table size={"small"} width={200}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={"left"}>id</TableCell>
                                            <TableCell align={"left"}>Name</TableCell>
                                            <TableCell align={"left"}>E-Mail</TableCell>
                                            <TableCell align={"left"}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((nc) => (
                                            <TableRow key={nc.id}>
                                                <TableCell align={"left"}>{nc.id}</TableCell>
                                                <TableCell align={"left"}>{nc.name}</TableCell>
                                                <TableCell align={"left"}>{nc.email}</TableCell>
                                                <TableCell align={"left"}>
                                                    <IconButton onClick={() => handleDeleteClick(nc.id!)}>
                                                        <Delete></Delete>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }

                    </Box>
                </Grid>
            </Grid>
        </>

    )
}
