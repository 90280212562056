import {Grid, Stack} from "@mui/material";
import {ChangePasswordForm} from "./ChangePasswordForm";
import {useRequireLogin} from "../../hooks/useRequireLogin";

export const AccountPage = () => {

    useRequireLogin();

    return (
        <>
            <Stack direction={"column"} spacing={2}>
                        <ChangePasswordForm/>
            </Stack>
        </>

    )
}
