import {Alert, Button, Stack, TextField} from "@mui/material";
import React, {useState} from "react";
import authService from "../service/api/authService";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import {LoginRequest} from "../model/dtos/Login";

export const LoginPage = () => {

  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [loginReguest, setLoginReguest] = useState<LoginRequest>({
    login: '',
    password: '',
  });

  const [error, setError] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoginReguest(prevState => ({...prevState, login: event.target.value}))
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLoginReguest(prevState => ({...prevState, password: event.target.value}))
  }

  const handleLoginClick = async () => {
    try {
      await authService.login({...loginReguest, login: loginReguest.login.trim()})
      await authService.getCurrentUserDetails();
      navigate('/')
    } catch (e) {
      snackbar.enqueueSnackbar('Uuups etwas ist schief gelaufen', {variant: "error"})
      console.error("Error while login", e)
      setError(true);
    }
  }

  return (
    <Stack
      direction={"column"}
      position={'absolute'}
      spacing={3}
    >
      <TextField type={"text"} onChange={handleNameChange}/>
      <TextField type={"password"} onChange={handlePasswordChange}></TextField>
      <Button onClick={handleLoginClick} variant={"contained"}>Login</Button>
      {error && <Alert severity={"error"} onClose={() => setError(false)}>Falsche Zugangsdaten</Alert>}    </Stack>
  )
}
