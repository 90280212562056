import {Link, Stack} from "@mui/material";
import React from "react";
import {useRequireAdminRole} from "../../hooks/useRequireAdminRole";

export const AdminPage = () => {


    useRequireAdminRole();

    return (
        <>
            <Stack direction={"column"} spacing={3}>
                <Link href={"/admin/player-overview"}>Spieler verwalten</Link>
                <Link href={"/admin/email-distributor"}>Email Verteiler</Link>
                <Link href={"/admin/add-tournament"}>Turnier hinzufügen</Link>
            </Stack>
        </>

    )
}
