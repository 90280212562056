import {appConfig} from "../../Constants";
import axios from "axios";
import {LoginRequest} from "../../model/dtos/Login";
import {UserDetails} from "../../model/UserDetails";
import authHeader from "./authHeader";
import {ChangePasswordRequest} from "../../model/dtos/ChangePasswordRequest";

const urlPrefix = `${appConfig.SERVER_URL}/auth`

const login = async (login: LoginRequest) => {
    return axios.post(urlPrefix + "/login", login)
        .then(response => {
                localStorage.setItem(appConfig.LOCAL_STORAGE_KEYS.JWT_TOKEN, response.data);
                return response.data;
            }
        )
}

const getCurrentUserDetails = async () => {
    return axios.get<UserDetails>(urlPrefix + "/current-user", {headers: authHeader()!})
        .then(response => {
            localStorage.setItem(appConfig.LOCAL_STORAGE_KEYS.CURRENT_USER, JSON.stringify(response.data));
            return response.data;
        });
};

const changePassword = (changePasswordRequest: ChangePasswordRequest) => {
    return axios.put(`${urlPrefix}/change-password`, changePasswordRequest, {headers: authHeader()!})
}

const authService = {
    login,
    getCurrentUserDetails,
    changePassword,
}

export default authService;
