import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

interface Props {
    title: string;
    open: boolean;
    onAccept: () => void;
    onCancel?: () => void;
    content: string;
}

export const AlertDialog = ({title, open, onAccept, onCancel, content}: Props) => {
    return(
        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                { onCancel && <Button onClick={onCancel}>Abbrechen</Button>}
                <Button onClick={onAccept} autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )

}
