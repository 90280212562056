import {NotificationConsumer} from "../../model/NotificationConsumer";
import {appConfig} from "../../Constants";
import axios from "axios";
import authHeader from "./authHeader";

const urlPrefix = `${appConfig.SERVER_URL}/notification-consumers`

const post = async (notificationConsumer: NotificationConsumer) => {
    return axios.post<NotificationConsumer>(urlPrefix, notificationConsumer, {headers: authHeader()!})
}

const deleteById = async (id: number) => {
    return axios.delete(`${urlPrefix}/${id}`, {headers: authHeader()!})
};

const notificationConsumerService = {
    post,
    deleteById
}

export default notificationConsumerService;
