const isDevelopment = false;

const dev = {
  SERVER_URL: "http://localhost:8080",
  SITE_NAME: "DEV",
  COLORS: {
    PRIMARY: {
      MAIN: "#e53935",
      LIGHT: "#ff6f60",
      DARK: "#ab000d"
    }
  },
  LOCAL_STORAGE_KEYS: {
    JWT_TOKEN: "JWT_TOKEN",
    CURRENT_USER: "CURRENT_USER",
  },
  AUTHORITIES: {
    ADMIN: "ADMIN"
  }
}

const prod = {
  SERVER_URL: process.env.REACT_APP_API_URL,
  SITE_NAME: process.env.REACT_APP_NAME,
  COLORS: {
    PRIMARY: {
      MAIN: "#e53935",
      LIGHT: "#ff6f60",
      DARK: "#ab000d"
    }
  },
  LOCAL_STORAGE_KEYS: {
    JWT_TOKEN: "JWT_TOKEN",
    CURRENT_USER: "CURRENT_USER",
  },
  AUTHORITIES: {
    ADMIN: "ADMIN"
  }
}

export const appConfig = isDevelopment ? dev : prod;
