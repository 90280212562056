import {Divider, List, ListItem, ListItemText, ListSubheader} from "@mui/material";
import React from "react";
import {TournamentEntries} from "../../model/TournamentEntries";

interface Props {
    tournamentEntries: TournamentEntries;
}

export const TournamentEntriesList = ({tournamentEntries}: Props) => {

    const maxPlayers = tournamentEntries.registered.length;

  return(
      <>
          <List dense>
              <ListSubheader>Anmeldungen</ListSubheader>

              {tournamentEntries.registered.map((value, index) => (
                  <ListItem key={value.id}>
                      <ListItemText>{index + 1}. {value.player.name}</ListItemText>
                  </ListItem>
              ))}
          </List>
          <Divider/>
          <List dense>
              <ListSubheader>Warteliste</ListSubheader>
              {tournamentEntries.waitingList.map((value, index) => (
                  <ListItem key={value.id}>
                      <ListItemText>{maxPlayers + index + 1}. {value.player.name}</ListItemText>
                  </ListItem>
              ))}
          </List>
      </>
  )
}
