import {Box, Button, Chip, Grid, Stack, TextField, Typography} from "@mui/material";
import {ChangeEvent, useState} from "react";
import {ChangePasswordRequest} from "../../model/dtos/ChangePasswordRequest";
import authService from "../../service/api/authService";
import currentUserService from "../../service/currentUserService";
import {useSnackbar} from "notistack";
import {useNavigate} from "react-router-dom";
import {AlertDialog} from "../../compontents/AlertDialog";
import authApi from "../../service/api/AuthApi";

export const ChangePasswordForm = () => {

    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [input, setInput] = useState({
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
    })

    const [openDialog, setOpenDialog] = useState(false);

    const handleCurrentPasswordChange = ( event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, currentPassword: event.target.value}))
    }

    const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, newPassword: event.target.value}))
    }

    const handleNewPasswordConfirmationChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, newPasswordConfirmation: event.target.value}))
    }

    const handleSubmit = async () => {
      const changePasswordRequest: ChangePasswordRequest = {
          userName: currentUserService.getCurrentUserName(),
          currentPassword: input.currentPassword,
          newPassword: input.newPassword,
      }
      try {
          await authService.changePassword(changePasswordRequest);
          setOpenDialog(true);

      } catch (e) {
        snackbar.enqueueSnackbar("Uuuuups etwas ist schief gelaufen!", {variant: "error"})
      }

    }


    const passwordsAreEqual = () => {
        return input.newPassword === input.newPasswordConfirmation;
    }

    function passwordLength() {
        return input.newPassword.length >= 8;
    }


    const inputIsValid = () => {
        return passwordsAreEqual() && passwordLength();
    }


    const handleAccept = () => {
        authApi.logout();
        navigate("/login");
    };

    return (
        <>
            <AlertDialog title={"Passwort erfolgreich geändert"} open={openDialog} onAccept={handleAccept}
                         content={"Du wirst zur login Seite weiter geleitet, wo du dich mit deinem neuen Password einlogen kannst"}></AlertDialog>
            <Typography variant={"h6"}>Password ändern</Typography>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Stack direction={"column"} spacing={2}>
                        <TextField label={"Aktuelles Passwort"} type={"password"} onChange={handleCurrentPasswordChange }></TextField>
                        <TextField label={"Neues Passwort"} name={"Neues Password"} type={"password"} onChange={handleNewPasswordChange}></TextField>
                        <TextField label={"Bestätigen"} name={"Neues Password"} type={"password"} onChange={handleNewPasswordConfirmationChange}></TextField>
                        <Box>
                            <Button disabled={!inputIsValid()} type={"submit"} variant={"contained"} onClick={handleSubmit}>{"Speichern"}</Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={6}>
                    <Stack direction={"column"} spacing={2}>
                        <Chip variant={"outlined"} color={passwordsAreEqual() ? "success" : "error"} label={"Passwörter stimmern überein"}></Chip>
                        <Chip variant={"outlined"} color={passwordLength() ? "success" : "error"} label={"Passwort hat mindestends acht Zeichen"}></Chip>
                    </Stack>
                </Grid>
            </Grid>
        </>


    )
}
