import {Avatar, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import {AccountCircle} from "@mui/icons-material";
import React from "react";
import currentUserService from "../../service/currentUserService";
import authApi from "../../service/api/AuthApi";
import {useNavigate} from "react-router-dom";

export const AccountButton = () => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfile = () => {
        setAnchorEl(null);
        navigate("/my-account")
    };

    const handleLogout = () => {
        authApi.logout();
        setAnchorEl(null);
        navigate("/login");
    };


    const handleClose = () => {
        setAnchorEl(null)
    };

    return (
            <>
                <IconButton onClick={handleMenu}>
                    <Avatar >

                    </Avatar>
                </IconButton>
                <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleProfile}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
            </>)





}
