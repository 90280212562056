import {ChangeEvent, useState} from "react";
import {postPlayer} from "../../../service/api/postPlayer";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useFetch} from "../../../service/api/useFetch";
import DeleteIcon from '@mui/icons-material/Delete';
import {Player} from "../../../model/Player";
import {Loading} from "../../../compontents/Loading";
import {Error} from "../../../compontents/Error";
import {RegisterPlayerRequest} from "../../../model/dtos/RegisterPlayerRequest";
import EditIcon from '@mui/icons-material/Edit';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {roleService} from "../../../service/roleService";
import {useRequireAdminRole} from "../../../hooks/useRequireAdminRole";
import playerService from "../../../service/api/playerService";
import {useSnackbar} from "notistack";

export const PlayerOverView = () => {

    useRequireAdminRole();

    const initialState: RegisterPlayerRequest = {
        name: "",
        login: "",
        email: ""
    };
    const [input, setInput] = useState(initialState)

    const {data, error, loading, refetch,} = useFetch<Player[]>("players");

    const isValidInput = () => {
        const {email, login, name} = input;
        return email && login && name
    }

    const snackbar = useSnackbar();

    const handleNameChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, name: event.target.value}))
    };

    const handleLoginChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, login: event.target.value}))
    };

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput(prevState => ({...prevState, email: event.target.value}))
    };

    const handleSubmit = async () => {
        try {
            await postPlayer(input)
            setInput(initialState)
            snackbar.enqueueSnackbar(`Spieler ${input.name} erfolgreich angelegt`, {variant: "success"})
        } catch (e) {
            snackbar.enqueueSnackbar("Uuuups etwas ist schiefgelaufen", {variant: "error"})
            console.error(e)
        }

        refetch();
    }

    const handleDeleteClick = async (id: number) => {
        try {
            await playerService.deletePlayer(id)
            snackbar.enqueueSnackbar(`Spieler ${input.name} erfolgreich entfernt`, {variant: "success"})
        } catch (e) {
            snackbar.enqueueSnackbar("Uuuups etwas ist schiefgelaufen", {variant: "error"})
            console.error(e)
        }

        refetch();
    };

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} spacing={3}>
                    <Stack direction={"column"} spacing={3}>
                        <Typography variant={"h5"}>Spieler anlegen:</Typography>
                        <Stack direction={'column'} spacing={3}>
                            <TextField label={'name'} onChange={handleNameChange}/>
                            <TextField label={'login'} onChange={handleLoginChange}/>
                            <TextField label={'email'} type={'email'} onChange={handlePasswordChange}></TextField>
                        </Stack>
                        <Box width={'50%'}>
                            <Button variant={"contained"} disabled={!isValidInput()}
                                    onClick={handleSubmit}>Speichern</Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        {loading && <Loading/>}
                        {error && <Error/>}
                        {data &&
                            <TableContainer component={Paper}>
                                <Table size={"small"} width={200}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={"left"}></TableCell>
                                            <TableCell align={"left"}>id</TableCell>
                                            <TableCell align={"left"}>Name</TableCell>
                                            <TableCell align={"left"}>Login</TableCell>
                                            <TableCell align={"left"}/>
                                            <TableCell align={"left"}/>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((player) => (
                                            <TableRow key={player.id}>
                                                <TableCell align={"left"}>
                                                    {roleService().playerIsAdmin(player) ?
                                                        <AdminPanelSettingsIcon></AdminPanelSettingsIcon> : <></>}
                                                </TableCell>
                                                <TableCell align={"left"}>{player.id}</TableCell>
                                                <TableCell align={"left"}>{player.name}</TableCell>
                                                <TableCell align={"left"}>{player.login}</TableCell>
                                                <TableCell align={"left"}>
                                                    <Button href={`/admin/edit-player/${player.id}`}>
                                                        <IconButton>
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Button>
                                                </TableCell>
                                                {
                                                    !roleService().playerIsAdmin(player) &&
                                                    <TableCell align={"left"}>
                                                        <Button onClick={() => handleDeleteClick(player.id)} >
                                                            <IconButton>
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Button>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}
