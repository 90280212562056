import {Grid, Typography} from "@mui/material";
import React from "react";
import {Tournament} from "../../model/Tournament";
import dayjs from "dayjs";

interface Props {
    tournament: Tournament
}

export const TournamentSummary = ({tournament}: Props) => {

    const tournamentStart = tournament.start ? dayjs(tournament.start).format('DD.MM.YYYY HH:mm') : "Termin folgt";

    return (
        <Grid container marginX={2}>
            <Grid item xs={6}>
                <Typography variant={"h6"}>Start:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant={"body1"} textAlign={'left'}>{tournamentStart}</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant={"h6"}>Spieleranzahl:</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography variant={"subtitle1"}>{tournament.maxPlayers}</Typography>
            </Grid>
        </Grid>
    )
}
