import {appConfig} from "../Constants";
import {UserDetails} from "../model/UserDetails";

const currentUserKey = appConfig.LOCAL_STORAGE_KEYS.CURRENT_USER;

function getUserDetailsFromLocalStorage() {
  const currentUser = localStorage.getItem(currentUserKey);
  if (!currentUser) return {authorities: [], username: ""};
  const userDetails: UserDetails = JSON.parse(currentUser);
  return userDetails;
}

const getCurrentUserName = () => {
  const userDetails = getUserDetailsFromLocalStorage();
  return userDetails.username;
}

const currentUserIsAuthenticated = (): boolean => {
  const item = localStorage.getItem(appConfig.LOCAL_STORAGE_KEYS.CURRENT_USER);
  return !!item;
};


const currentUserIsAdmin = () => {
  return getUserDetailsFromLocalStorage().authorities
      .map(user => user.authority)
      .includes(appConfig.AUTHORITIES.ADMIN)
}

const currentUserHasRoles = (authority: string) => {
  return getUserDetailsFromLocalStorage().authorities
    .map(user => user.authority)
    .includes(authority);
};


const currentUserService = {
  getCurrentUserName,
  currentUserIsAuthenticated,
  currentUserIsAdmin,
  currentUserHasRoles,
}

export default currentUserService;
