import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import axios, {AxiosError} from "axios";
import {UserDetails} from "../model/UserDetails";
import {appConfig} from "../Constants";
import authHeader from "../service/api/authHeader";

export const useRequireRole = (role: string) => {

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await axios.get<UserDetails>(`${appConfig.SERVER_URL}/auth/current-user`, {headers: authHeader()!});
                const userDetails = response.data;
                if (!userDetails.authorities.map(auth => auth.authority).includes(role)) navigate("/forbidden")
                return response.data;
            } catch (error) {
                if ((error as AxiosError).response?.status == 403) {
                    navigate("/forbidden")
                }
            }
        }
        fetchCurrentUser();

    }, []);

}
