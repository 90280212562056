import {Box, Stack, Typography} from "@mui/material";

interface Props {
  tournamentName: string
}


export const TournamentHeader = ({tournamentName}: Props) => {
  return(
    <Box bgcolor={"primary.main"} paddingLeft={2}>
        <Typography variant={"h5"}>{tournamentName}</Typography>
    </Box>
  )
}
