import {CircularProgress, IconButton, Tooltip} from "@mui/material";
import {PersonAdd, PersonRemove} from "@mui/icons-material";
import React from "react";




interface Props {
    currentUserIsRegistered: boolean;
    handleUnregister: () => void;
    handleRegister: () => void;
    loading: boolean

}

export const RegisterForTournamentButton = ({currentUserIsRegistered, handleRegister, handleUnregister, loading}: Props) => {

    if (loading) {
        return (
            <CircularProgress ></CircularProgress>
        )
    }

    return(
            currentUserIsRegistered
            ?
            <Tooltip title={"Abmelden"}>
                <IconButton onClick={handleUnregister}>
                    <PersonRemove/>
                </IconButton>
            </Tooltip>
            :
            <Tooltip title={"Anmelden"}>
                <IconButton onClick={handleRegister}>
                    <PersonAdd/>
                </IconButton>
            </Tooltip>
    );

}
