import {Autocomplete, Box, Button, Modal, Select, Stack, TextField} from "@mui/material";
import useAxios from "axios-hooks";
import {Player} from "../model/Player";
import {Loading} from "./Loading";
import {Error} from "./Error";
import {useState} from "react";
import {appConfig} from "../Constants";
import authHeader from "../service/api/authHeader";

interface Props {
  isOpen: boolean;
  onClose: (userId?: number) => void
}

export const SelectUserModal = ({isOpen, onClose}: Props) => {

  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>()

  const handleSubmit = () => {
    onClose(selectedPlayer?.id)
  };

  const [{data, error, loading}, refetch] = useAxios<Player[]>({
    url: appConfig.SERVER_URL + '/players',
    method: "GET",
    headers: authHeader()!,
  });

  return (

    <Modal open={isOpen}>
      <Box
        position={'absolute'}
        top={'50%'}
        left={'50%'}
        bgcolor={'background.paper'}
        boxShadow={24}
        padding={4}
        sx={{
          transform: 'translate(-50%, -50%)'
        }}
      >
        <Stack direction={"column"} spacing={3}>
          {loading && <Loading/>}
          {error && <Error/>}
          {data && <Autocomplete
              options={data}
              renderInput={(params) => <TextField {...params} label="name"/>}
              getOptionLabel={option => option.name}
              onChange={(event: any, newValue: Player | null) => {
                if (newValue)
                  setSelectedPlayer(newValue);
              }}
          />
          }
          <Button variant={"contained"} onClick={handleSubmit}>Speichern</Button>
        </Stack>

      </Box>
    </Modal>
  )
}
