import axios from "axios";
import {appConfig} from "../../Constants";

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(appConfig.SERVER_URL + "/players/login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem(appConfig.LOCAL_STORAGE_KEYS.CURRENT_USER);
    localStorage.removeItem(appConfig.LOCAL_STORAGE_KEYS.JWT_TOKEN);
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }
}
export default new AuthService();
