import React from 'react';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {CustomAppBar} from "./compontents/app-bar/CustomAppBar";
import {TournamentOverview} from "./pages/TournamentOverview";
import {Box, createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {AdminPage} from "./pages/adminPage/AdminPage";
import {EditTournament} from "./pages/EditTournament/EditTournament";
import {LoginPage} from "./pages/LoginPage";
import {appConfig} from "./Constants";
import {PlayerOverView} from "./pages/adminPage/playerOverview/PlayerOverView";
import {EmailDistributor} from "./pages/adminPage/emailDistributor/EmailDistributor";
import {AccountPage} from "./pages/accountPage/AccountPage";
import {SnackbarProvider} from "notistack";
import {AddTournamentPage} from "./pages/add-tournament-page/AddTournamentPage";
import {EditPlayerPage} from "./pages/adminPage/edit-player-page/EditPlayerPage";
import {ForbiddenSite} from "./pages/forbidden-site/ForbiddenSite";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import deLocale from 'date-fns/locale/de'


const theme = createTheme({
    palette: {
        primary: {
            main:  appConfig.COLORS.PRIMARY.MAIN,
            light: appConfig.COLORS.PRIMARY.LIGHT,
            dark: appConfig.COLORS.PRIMARY.DARK
        },
    }
})

function App() {
    return (
        <SnackbarProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
                    <BrowserRouter>
                        <div className="App">
                            <CustomAppBar/>
                            <Box margin={4}>
                                <Routes>
                                    <Route path={'/'} element={<TournamentOverview/>}/>
                                    <Route path={'/login'} element={<LoginPage/>}/>
                                    <Route path={'/admin'} element={<AdminPage/>}/>
                                    <Route path={'/admin/player-overview'} element={<PlayerOverView/>}/>
                                    <Route path={"/admin/email-distributor"} element={<EmailDistributor/>}/>
                                    <Route path={"/admin/add-tournament"} element={<AddTournamentPage/>}/>
                                    <Route path={'/admin/edit-player/:id'} element={<EditPlayerPage/>}/>
                                    <Route path={'tournaments/:id/edit'} element={<EditTournament/>}/>
                                    <Route path={'my-account'} element={<AccountPage/>}/>
                                    <Route path={'forbidden'} element={<ForbiddenSite/>}/>
                                </Routes>
                            </Box>
                        </div>
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

export default App;
