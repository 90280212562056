import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import axios, {AxiosError} from "axios";
import {UserDetails} from "../model/UserDetails";
import {appConfig} from "../Constants";
import authHeader from "../service/api/authHeader";

export const useRequireLogin = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await axios.get<UserDetails>(`${appConfig.SERVER_URL}/auth/current-user`, {headers: authHeader()!});
                return response.data;
            } catch (error) {
                if ((error as AxiosError).response?.status == 403) {
                    navigate("/forbidden")
                }
            }
        }
        fetchCurrentUser();

    }, []);
}
