import {Player} from "../model/Player";
import {appConfig} from "../Constants";

export const roleService = () => {

    const playerIsAdmin = (player: Player) => {
        return  player.roles.map(value => value.authority)
            .includes(appConfig.AUTHORITIES.ADMIN);
    }

    return{
        playerIsAdmin,
    }
}
