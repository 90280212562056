import {Tournament} from "../../model/Tournament";
import {Box, Card, IconButton, Stack, Tooltip} from "@mui/material";
import {TournamentHeader} from "./TournamentHeader";
import EditIcon from '@mui/icons-material/Edit';
import React, {useState} from "react";
import {useFetch} from "../../service/api/useFetch";
import {TournamentEntries} from "../../model/TournamentEntries";
import {useNavigate} from "react-router-dom";
import currentUserService from "../../service/currentUserService";
import {appConfig} from "../../Constants";
import {useSnackbar} from "notistack";
import {useTournamentService} from "../../service/api/tournamentService";
import {RegisterForTournamentButton} from "./RegisterForTournamentButton";
import {TournamentSummary} from "./TournamentSummary";
import {TournamentEntriesList} from "./TournamentEntriesList";
import {usePut} from "../../service/usePut";
import {AlertDialog} from "../AlertDialog";

interface Props {
    tournament: Tournament;
}

export const TournamentCard = ({tournament}: Props) => {

    const tournamentService = useTournamentService();

    const [openUnregisterDialog, setOpenUnregisterDialog] = useState(false)

    const {
        data, loading, refetch, error
    } = useFetch<TournamentEntries>(`tournaments/${tournament.id}/entries`);

    const {
        executePut,
        putData,
        putError,
        putLoading: registerLoading
    } = usePut<Tournament>(`tournaments/${tournament.id}/self-register`);

    const {
        executePut: executeUnregister ,
        putLoading: unregisterLoading
    } = usePut<Tournament>(`tournaments/${tournament.id}/self-unregister`);

    const navigateFunction = useNavigate();
    const snackbar = useSnackbar();

    if (error && error.response?.status === 403) {
        navigateFunction("/login")
    }

    const currentUserIsAdmin = currentUserService.currentUserHasRoles(appConfig.AUTHORITIES.ADMIN);


    const currentUserIsRegistered = () => {
        const currentUserName = currentUserService.getCurrentUserName();
        if (!data?.registered || !data.waitingList) return false;
        const entries = data.registered.concat(data.waitingList);
        return entries.map(value => value.player.login)
            .includes(currentUserName);
    }


    const handleUnregister = async () => {
        setOpenUnregisterDialog(false)
        try {
            await executeUnregister();
            snackbar.enqueueSnackbar(`Du hast dich von ${tournament.name} abgemeldet!`, {variant: "success"})
        } catch (e) {
            snackbar.enqueueSnackbar('Uuup etwas ist schiefgelaufen!', {variant: "error"})
            console.error(e)
        }
        refetch();
    }

    const handleRegister = async () => {
        try {
            await executePut();
            snackbar.enqueueSnackbar(`Du bist nun in ${tournament.name} angemeldet!`, {variant: "success"})
        } catch (e) {
            snackbar.enqueueSnackbar('Uuup etwas ist schiefgelaufen!', {variant: "error"})
            console.error(e)
        }
        refetch();
    };

    return (
        <>
<AlertDialog title={""} open={openUnregisterDialog} onAccept={handleUnregister}
             onCancel={() => setOpenUnregisterDialog(false)}
             content={`Möchtest du dich wirklich vom ${tournament.name} abmelden?`}/>
        <Card elevation={3}>
            <TournamentHeader tournamentName={tournament.name}/>
            <Box>
                <TournamentSummary tournament={tournament}/>
                {data && <TournamentEntriesList tournamentEntries={data}/>}
                <Stack direction={"row-reverse"}>
                    {
                        currentUserIsAdmin &&
                        <Tooltip title={"bearbeiten"}>
                            <IconButton href={`/tournaments/${tournament.id}/edit`}>
                                <EditIcon></EditIcon>
                            </IconButton>
                        </Tooltip>

                    }
                    {tournament.openForRegistration &&
                        <RegisterForTournamentButton currentUserIsRegistered={currentUserIsRegistered()}
                                                     handleUnregister={() => setOpenUnregisterDialog(true)}
                                                     handleRegister={handleRegister}
                                                     loading={registerLoading || unregisterLoading}
                        />
                    }
                </Stack>
            </Box>
        </Card>
        </>
    )
}
