import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {AdminPanelSettings, Home} from "@mui/icons-material";
import React, {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import packageJson from './../../../package.json';
import currentUserService from "../../service/currentUserService";


export const NavDrawer = () => {

    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    return (
        <>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{mr: 2}}
                onClick={toggleDrawer}
            >
                <MenuIcon/>
            </IconButton>
            <Drawer
                anchor={"left"}
                open={openDrawer}
                onClose={toggleDrawer}
            >
                <Box width={250} height={'100%'}>
                    <List>
                        <ListItemButton href={"/"}>
                            <ListItemIcon>
                                <Home></Home>
                            </ListItemIcon>
                            <ListItemText primary={"Home"}></ListItemText>
                        </ListItemButton>
                        {currentUserService.currentUserIsAdmin() &&
                            <ListItemButton href={"/admin"}>
                                <ListItemIcon>
                                    <AdminPanelSettings></AdminPanelSettings>
                                </ListItemIcon>
                                <ListItemText primary={"Admin Seite"}></ListItemText>
                            </ListItemButton>
                        }
                        <Divider/>
                        <ListItemButton href={"https://ps-paw.de.tl/"}>
                            <ListItemIcon>
                                <Home></Home>
                            </ListItemIcon>
                            <ListItemText primary={"PS Poker"}></ListItemText>
                        </ListItemButton>
                    </List>

                </Box>
                <Typography bottom={0} color={"primary"}>{`version: ${packageJson.version}`}</Typography>
            </Drawer>
        </>

    );
}
