import {useEffect, useState} from "react";
import axios, {AxiosError} from "axios";
import {appConfig} from "../../Constants";
import {useNavigate} from "react-router-dom";
import authHeader from "./authHeader";
import useAxios from "axios-hooks";

export const useFetch = <T>(urlAppendix: string) => {

  const navigate = useNavigate();

  if (!authHeader()) navigate("/login")

 const [{data, loading, response, error}, refetch ] =useAxios<T>({
    url: `${appConfig.SERVER_URL}/${urlAppendix}`,
    headers: authHeader()!,
  })



  return {
    data,
    loading,
    response,
    error,
    refetch
  };
}
