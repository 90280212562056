import useAxios from "axios-hooks";
import {appConfig} from "../Constants";
import authHeader from "./api/authHeader";

export const usePut = <T>(path: string) => {
    const [
        {data: putData, loading: putLoading, error: putError},
        executePut
    ] = useAxios<T>(
        {
            url: `${appConfig.SERVER_URL}/${path}`,
            method: 'PUT',
            headers: authHeader()!,
        },
        {manual: true}
    )


    return {
        putData,
        putLoading,
        putError,
        executePut
    }
}
